//React

import React from "react";

function Documents() {

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior:"instant"
    });
    document.title = process.env.REACT_APP_NAME + ' - Dokumentumok';
  }, []);

  return (
    <section id="documents" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
      <h2 className="font-extrabold text-center text-17 2xl:text-19 mb-10">Dokumentumok</h2>
      <div className="flex flex-col lg:gap-6 gap-4 max-w-[32rem] m-auto">
        <a href="/documents/Adatkezeles-1.pdf" download className="flex flex-row justify-between gap-2 items-center border border-transparent hover:text-secondary hover:border-secondary rounded-full hover:border text-black w-full pl-2 pr-2">
          <p className="text-12 2xl:text-15 font-medium">Adatkezelési tájékoztató-1</p>
          <img src="/assets/logos/download.png" alt="download" className="h-8 lg:h-12" />
        </a>
        <a href="/documents/Adatkezeles-2.pdf" download className="flex flex-row justify-between gap-2 items-center border border-transparent hover:text-secondary hover:border-secondary rounded-full hover:border text-black w-full pl-2 pr-2">
          <p className="text-12 2xl:text-15 font-medium">Adatkezelési tájékoztató-2</p>
          <img src="/assets/logos/download.png" alt="download" className="h-8 lg:h-12" />
        </a>
        <a href="/documents/ASZF-1.pdf" download className="flex flex-row justify-between gap-2 items-center border border-transparent hover:text-secondary hover:border-secondary rounded-full hover:border text-black w-full pl-2 pr-2">
          <p className="text-12 2xl:text-15 font-medium">ÁSZF-1</p>
          <img src="/assets/logos/download.png" alt="download" className="h-8 lg:h-12" />
        </a>
        <a href="/documents/ASZF-2.pdf" download className="flex flex-row justify-between gap-2 items-center border border-transparent hover:text-secondary hover:border-secondary rounded-full hover:border text-black w-full pl-2 pr-2">
          <p className="text-12 2xl:text-15 font-medium">ÁSZF-2</p>
          <img src="/assets/logos/download.png" alt="download" className="h-8 lg:h-12" />
        </a>
        <a href="/documents/Irasos tajekoztato es vallalkozasi feltetelek.pdf" download className="flex flex-row justify-between gap-2 items-center border border-transparent hover:text-secondary hover:border-secondary rounded-full hover:border text-black w-full pl-2 pr-2">
          <p className="text-12 2xl:text-15 font-medium">Írásos tájékoztató és vállalkozási feltételek</p>
          <img src="/assets/logos/download.png" alt="download" className="h-8 lg:h-12" />
        </a>
        <a href="/documents/Tanuloi szerzodes.pdf" download className="flex flex-row justify-between gap-2 items-center border border-transparent hover:text-secondary hover:border-secondary rounded-full hover:border text-black w-full pl-2 pr-2">
          <p className="text-12 2xl:text-15 font-medium">Tanulói szerződés</p>
          <img src="/assets/logos/download.png" alt="download" className="h-8 lg:h-12" />
        </a>
      </div>
    </section>
  );
}

export default Documents;
