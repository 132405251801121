//React

import React from "react";
import axios from 'axios';
//Components

import toast from 'react-hot-toast';
//Helpers

import {
  telephoneRegex,
  emailRegex
} from "helpers/helpers";
import { ErdeklodoModel } from "helpers/models/erdeklodo";


function Contacts() {

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant"
    });
    document.title = process.env.REACT_APP_NAME + ' - Kapcsolat';
  }, [])

  //Form
  const [form, setForm] = React.useState({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    message: ""
  })

  const [loading, setLoading] = React.useState(false);

  const [validEmail, setValidEmail] = React.useState(true);
  const [validPhone, setValidPhone] = React.useState(true);

  const validLastName = !(form.lastName === null || form.lastName === undefined || form.lastName == "");
  const validFirstName = !(form.firstName === null || form.firstName === undefined || form.firstName == "");
  const validEmailField = !(form.email === null || form.email === undefined || form.email == "");
  const validPhoneNumber = !(form.phoneNumber === null || form.phoneNumber === undefined || form.phoneNumber == "");
  const validMessage = !(form.message === null || form.message === undefined || form.message == "");

  const validateForm = validEmail && validPhone && validLastName && validFirstName && validEmailField && validPhoneNumber && validMessage;

  const handleChange = (e) => {
    const enteredValue = e.target.value;
    const inputType = e.target.type;
    const name = e.target.name;
    if (inputType === "email") {
      setValidEmail(emailRegex.test(enteredValue) || (enteredValue === null || enteredValue === undefined || enteredValue == ""));
    }
    if (name === "phoneNumber") {
      setValidPhone(telephoneRegex.test(enteredValue) || (enteredValue === null || enteredValue === undefined || enteredValue == ""));
    }

    setForm(prevState => ({
      ...prevState,
      [name]: enteredValue
    }));
  }

  function onSubmit(event) {
    event.preventDefault()

    setLoading(true);
    const model = new ErdeklodoModel(form.lastName, form.firstName, form.email, form.phoneNumber, form.message);

    axios.post(`${process.env.REACT_APP_API_URL}/erdeklodos`, { data: model }, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_CONTACT_TOKEN}` } })
      .then(res => {
        toast.success('Az érdeklődés elküldve!', { duration: 5000 });
        setForm({
          lastName: "",
          firstName: "",
          email: "",
          phoneNumber: "",
          message: ""
        });
      }).catch(err => {
        console.error(err);
        toast.error('Az érdeklődést nem sikerült elküdeni!', { duration: 5000 });
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <section id="contacts">
      <h4 className="font-semibold mb-4 text-12 2xl:text-15 pl-6 pr-6 2xl:pr-0 2xl:pl-0">
        Kérdésed van? Írj nekünk!
      </h4>
      <div className="contacts-container flex flex-col 2xl:flex-row gap-4 w-full mt-10 2xl:mt-14">
        <div className="first-col w-full 2xl:w-1/2 pl-6 pr-6 2xl:pr-0 2xl:pl-0">
          <form>
            <input className={`${loading ? " bg-gray-200" : "bg-white"} h-8 lg:h-9 shadow-sm`} disabled={loading} type="text" id="flast_name" name="lastName" placeholder="Vezetéknév*" value={form?.lastName} required onChange={handleChange} />
            <input className={`${loading ? "bg-gray-200" : "bg-white"} h-8 lg:h-9 shadow-sm`} disabled={loading} type="text" id="first_name" name="firstName" placeholder="Keresztnév*" value={form?.firstName} required onChange={handleChange} />
            <input disabled={loading} type="email" className={`${loading ? " bg-gray-200" : "bg-white"} ${!validEmail ? "not-valid" : ""} h-8 lg:h-9 shadow-sm`} id="email" name="email" placeholder="E-mail cím*" value={form?.email} onChange={handleChange} required />
            {/*validEmail ? null : <span className="text-red-600 text-12">Kérlek adj meg egy valódi e-mail címet!</span>*/}
            <input disabled={loading} type="text" className={`${loading ? " bg-gray-200" : "bg-white"} ${!validPhone ? "not-valid" : ""} h-8 lg:h-9 shadow-sm`} id="phone_number" name="phoneNumber" placeholder="Telefonszám*" value={form?.phoneNumber} onChange={handleChange} required />
            {/*validPhone ? null : <span className="text-red-600 text-12">Kérlek adj meg egy valódi telefonszámot!</span>*/}
            <textarea className={`${loading ? "bg-gray-200" : "bg-white"} shadow-sm`} disabled={loading} rows="8" type="text" id="message" name="message" placeholder="Üzenet*" value={form?.message} onChange={handleChange} required />
            <span className="font-normal text-10 block 2xl:hidden">*Kötelezően kitöltendő</span>
            <a href="/documents/ASZF-2.pdf" download className="flex flex-row gap-2 items-center hover:text-secondary text-black 2xl:hidden">
              <p className="text-10 2xl:text-12 font-medium">Adatkezelési tájékoztató</p>
            </a>
            <div className="flex flex-row gap-4 items-center 2xl:hidden">
              <img className="check-icon w-4 2xl:w-8" src="/assets/logos/check-logo-white.png" alt="check-logo" />
              <p className="font-normal text-10 2xl:text-12">Hozzájárulok az adatkezelési tájékoztató szerinti adatkezeléshez</p>
            </div>
            <button disabled={!validateForm} onClick={(event) => onSubmit(event)} className="2xl:hidden text-black bg-transparent flex items-center justify-between flex-row border border-black w-36 pl-5 pr-5 pt-2 pb-2 mb-4 mt-4 ml-auto disabled:bg-secondary disabled:opacity-35 enabled:hover:bg-primary enabled:hover:shadow-xl enabled:hover:border-transparent">
              <p className="font-medium text-10 2xl:text-15">Küldés</p>
              {loading ?
                <svg className="w-auto h-4 2xl:h-6 text-active animate-spin z-20" viewBox="0 0 64 64" fill="none"
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path
                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                    stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path
                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                    stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" className="text-gray-900">
                  </path>
                </svg>
                : <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 2xl:h-3" />
              }
            </button>
          </form>
        </div>
        <div className="second-col w-full 2xl:w-1/2 bg-primary rounded-none 2xl:justify-center lg:rounded-1.625 p-6 2xl:pl-16 2xl:pr-16 flex flex-col">
          <h4 className="font-semibold mb-1 text-14 2xl:text-17">
            Érdeklődj telefonon
          </h4>
          <p className="mb-2 2xl:mb-6 text-12 2xl:text-14">
            Keresd kollégáinkat munkanapokon 9:00 és 17:00
          </p>
          <div className="flex flex-row gap-2 2xl:gap-4 items-center">
            <img src="/assets/logos/phone.png" alt="check-logo" className="check-icon w-4 2xl:w-6" />
            <p className="font-normal text-12 2xl:text-14">+36 70 4020 112</p>
          </div>
          <h4 className="font-semibold mt-8 2xl:mt-16 mb-1 text-12 2xl:text-17">
            Nézz be hozzánk
          </h4>
          <p className="text-12 2xl:text-14">
            Ha jobban szeretsz személyesen egyeztetni, látogass el irodánkba.
          </p>
          <p className="mt-2 text-12 2xl:text-14">
            <strong>Nyitva tartás:</strong>
          </p>
          <p className="text-12 2xl:text-14">
            <strong>Szerda</strong> 08:00-12:00
          </p>
          <p className="text-12 2xl:text-14">
            <strong>Péntek</strong> 12:00-16:00
          </p>
          <p className="mt-2 text-12 2xl:text-14 mb-2 2xl:mb-6"><span className="font-semibold">Extra nyitva tartás:</span> előzetes telefonon bejelentkezés alapján hétfőtől péntekig
                08:00-20:00 között</p>
          <div className="flex flex-row gap-2 2xl:gap-4 items-center 2xl:mb-0">
            <img src="/assets/logos/pin.png" alt="check-logo" className="check-icon w-4 2xl:w-6" />
            <p className="font-medium text-12 2xl:text-14">2600 Vác, Görgey Artúr utca 1.</p>
          </div>
        </div>
      </div>
      <div className="contacts-footer w-full 2xl:w-1/2 pl-6 pr-6 2xl:pr-0 2xl:pl-0 mt-2">
        <span className="font-normal text-10 hidden 2xl:block">*Kötelezően kitöltendő</span>
        <a href="/documents/ASZF-2.pdf" download className="flex flex-row gap-2 items-center hover:text-secondary text-black">
          <p className="2xl:text-12 font-medium">Adatkezelési tájékoztató</p>
        </a>
        <div className="hidden 2xl:flex flex-row gap-4 items-center">
          <img className="check-icon w-4 2xl:w-6" src="/assets/logos/check-logo-white.png" alt="check-logo" />
          <p className="font-normal text-10 2xl:text-12">Hozzájárulok az adatkezelési tájékoztató szerinti adatkezeléshez</p>
        </div>
        <button disabled={!validateForm} onClick={(event) => onSubmit(event)} className="text-black bg-transparent hidden 2xl:flex items-center justify-between flex-row border border-black w-36 2xl:w-52 pl-5 pr-5 pt-3 pb-3 mb-4 mt-4 ml-auto disabled:bg-secondary disabled:opacity-35 enabled:hover:bg-primary enabled:hover:shadow-xl enabled:hover:border-transparent">
          <p className="font-medium text-10 2xl:text-15">Küldés</p>
          {loading ?
            <svg className="w-auto h-4 2xl:h-6 text-active animate-spin z-20" viewBox="0 0 64 64" fill="none"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" className="text-gray-900">
              </path>
            </svg>
            : <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 2xl:h-4" />
          }
        </button>
      </div>
      <iframe
        className="mt-10 2xl:mt-16 2xl:rounded-1.9375 rounded-none"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCrgIRhWBRp-j-3jpa8XiGIuWyS1fl2VsE&q=Rajt Autósiskola">
      </iframe>
    </section>
  );
}

export default Contacts;
