//React

import React from "react";

function GiftCard() {

  React.useEffect(() => {
    document.title = process.env.REACT_APP_NAME + ' - Ajándékutalvány';
  }, []);

  return (
    <>
      <h1 className="text-3xl">Ajándékutalvány</h1>
      <h2 className="m-auto text-center text-15 2xl:text-20">
        Az oldal fejlesztés alatt áll...<br></br>Nézz vissza később!
      </h2>
    </>
  );
}

export default GiftCard;
