//React

import React from "react";
//Router
import { NavLink } from "react-router-dom";

function NotFound() {

    React.useEffect(() => {
        document.title = process.env.REACT_APP_NAME + ' - Nem található';
    }, []);

    return (
        <section id="not-found" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
            <div className="flex flex-col items-center">
                <h1 className="text-2xl mb-4">Nem található a keresett oldal...</h1>
                <NavLink to="/" className="bg-primary w-32 2xl:w-36 pt-2 pb-2 2xl:pt-3 2xl:pb-3 rounded-full uppercase text-center hover:bg-active hover:shadow-lg">
                    <p className="font-bold text-10 2xl:text-12">
                        Kezdőlap
                    </p>
                </NavLink>
            </div>

        </section>
    );
}

export default NotFound;
