//React

import React from "react";
//Router

import { NavLink } from "react-router-dom";
//Components

import ClickAwayListener from 'react-click-away-listener';

const Header = props => {
  const { } = props;

  const [openDropDown, setOpenDropDown] = React.useState(false);
  const [openMobileDropDown, setOpenMobileDropDown] = React.useState(false);
  const [openHamburger, setOpenHamburger] = React.useState(false);

  const handleClickAway = () => {
    if (openDropDown) setOpenDropDown(false);
  };

  const onClickMobileDropdDownItem = () => {
    setOpenHamburger(false);
    setOpenMobileDropDown(false);
  }

  return (
    <header className="shadow-lg bg-white w-full h-16 2xl:h-[4.5rem] mb-6 2xl:mb-9">
      <div className="header-container pl-2 pr-2 2xl:pl-0 2xl:pr-0">
        <NavLink to="/">
          <img id="header-logo" src="/assets/logos/colored-logo.png" className=" h-8 2xl:h-9" />
        </NavLink>
        <nav>
          <ul id="header-nav">
            <li className="font-medium"><NavLink to="/">Rólunk</NavLink></li>
            <li className="font-medium"><NavLink to="/oktatoink">Oktatóink</NavLink></li>
            <li className="font-medium"><NavLink to="araink">Áraink</NavLink></li>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="dropdown">
                <li className="font-medium cursor-pointer flex flex-row items-center gap-2" onClick={() => setOpenDropDown(prevState => !prevState)}>
                  Információk
                  <img src="/assets/logos/chevron-bottom.png" alt="chevron-bottom" className={`${openDropDown ? 'rotate-180' : ""} h-[0.35rem] w-auto`} />
                </li>
                <div className={`absolute z-50 shadow-md bg-white rounded-3xl p-2 ${openDropDown ? 'block' : 'hidden'}`}>
                  <NavLink to="/informaciok/szerezz-jogsit" onClick={() => setOpenDropDown(false)} className="block font-medium text-14 mb-2">Szerezz jogsit!</NavLink>
                  <NavLink to="/informaciok/elsosegely" onClick={() => setOpenDropDown(false)} className="block font-medium text-14 mb-2">Elsősegély</NavLink>
                  <NavLink to="/informaciok/dokumentumok" onClick={() => setOpenDropDown(false)} className="block font-medium text-14 mb-2">Dokumentumok</NavLink>
                  <NavLink to="/informaciok/akciok" onClick={() => setOpenDropDown(false)} className="block font-medium text-14 mb-2">Akciók</NavLink>
                  <NavLink to="/informaciok/partnerek" onClick={() => setOpenDropDown(false)} className="block font-medium text-14 mb-2">Partnerprogram</NavLink>
                  {/*<NavLink to="/informaciok/ajandekutalvany" onClick={() => setOpenDropDown(false)} className="block font-medium text-14">Ajándékutalvány</NavLink>*/}
                  <NavLink to="/informaciok/pszichologus" onClick={() => setOpenDropDown(false)} className="block font-medium text-14 mb-2">Pszichológus</NavLink>                
                </div>
              </div>
            </ClickAwayListener>
            {/*<li className="font-medium"><NavLink to="/blog">Blog</NavLink></li>*/}
            <li className="font-medium"><NavLink to="/kapcsolat">Kapcsolat</NavLink></li>
          </ul>
        </nav>
        <NavLink to="/kapcsolat" className="bg-primary w-32 2xl:w-36 pt-2 pb-2 2xl:pt-3 2xl:pb-3 rounded-full uppercase text-center hover:bg-active hover:shadow-lg hidden 2xl:block">
          <p className="font-bold text-10 2xl:text-12">
            JELENTKEZEM
          </p>
        </NavLink>
        <button type="button" onClick={() => setOpenHamburger(prevState => !prevState)} className="inline-flex items-center justify-center p-2 w-8 h-8 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 2xl:hidden" aria-controls="navbar-hamburger" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
      </div>
      <div className={`w-full z-[1000] ${openHamburger ? 'is-open' : ''} 2xl:hidden flex absolute`} id="navbar-hamburger">
        <div className="w-full">
          <ul className="flex flex-col w-full font-medium mt-4 rounded-lg bg-gray-50 gap-1 pb-2">
            <li className="font-medium"><NavLink to="/" onClick={() => setOpenHamburger(false)} className="pt-2 pb-2"><span className="pl-4">Rólunk</span></NavLink></li>
            <li className="font-medium"><NavLink to="/oktatoink" onClick={() => setOpenHamburger(false)} className="pt-2 pb-2"><span className="pl-4">Oktatóink</span></NavLink></li>
            <li className="font-medium"><NavLink to="araink" onClick={() => setOpenHamburger(false)} className="pt-2 pb-2"><span className="pl-4">Áraink</span></NavLink></li>
            <li className="font-medium cursor-pointer flex flex-row items-center gap-2 w-full justify-between" onClick={() => setOpenMobileDropDown(prevState => !prevState)}>
              <a>
                <span className="pl-4">Információk</span>
                <img src="/assets/logos/chevron-bottom.png" alt="chevron-bottom" className={`${openMobileDropDown ? 'rotate-180' : ""} pr-4 pl-4 h-2 w-auto`} />
              </a>
            </li>
            <div className="flex">
              <div className="w-full">
                <div id="hamburger-drop-down" className={`${openMobileDropDown ? 'is-open' : ""}`}>
                  <NavLink to="/informaciok/szerezz-jogsit" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Szerezz jogsit!</NavLink>
                  <NavLink to="/informaciok/elsosegely" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Elsősegély</NavLink>
                  <NavLink to="/informaciok/dokumentumok" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Dokumentumok</NavLink>
                  <NavLink to="/informaciok/akciok" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Akciók</NavLink>
                  <NavLink to="/informaciok/partnerek" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Partnerprogram</NavLink>
                  {/*<NavLink to="/informaciok/ajandekutalvany" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Ajándékutalvány</NavLink>*/}
                  <NavLink to="/informaciok/pszichologus" onClick={onClickMobileDropdDownItem} className="block font-medium text-14 pl-6">Pszichológus</NavLink>              
                </div>
              </div>            
            </div>           
            {/*<li className="font-medium"><NavLink to="/blog" onClick={() => setOpenHamburger(false)} className="pt-2 pb-2"><span className="pl-4">Blog</span></NavLink></li>*/}
            <li className="font-medium"><NavLink to="/kapcsolat" onClick={() => setOpenHamburger(false)} className="pt-2 pb-2"><span className="pl-4">Kapcsolat</span></NavLink></li>
          </ul>
        </div>
        
      </div>
    </header>
  );
};

export default Header;