//React

import { BrowserRouter } from "react-router-dom";
//Theme

import "./assets/base.scss";
//Components

import { AppRoutes } from "./router";
import { Toaster } from 'react-hot-toast';
import { PopUp } from "layout-components";


function App() {

  return (
    <>
      <Toaster toastOptions={{
        style: {
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          fontFamily: "'Urbanist', sans-serif",
          fontWeight: '500',
        },
        success: {
          style: {
            backgroundColor: '#C6FF24',
            color: 'black'
          },
          iconTheme: {
            primary: 'black',
            secondary: 'white',
          }
        },
        error: {
          style: {
            backgroundColor: 'black',
            color: 'white'
          }
        }
      }} />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
