
export class ErdeklodoModel{

    constructor(lastName, firstName, email, phoneNumber, message) {
        this.Vezeteknev=lastName;
        this.Keresztnev=firstName;
        this.Email = email;
        this.Telefonszam = phoneNumber;
        this.Uzenet = message;
        this.AdatkezelesiHozzajarulas= true;
      }
}