//React

import React from "react";
import PropTypes from "prop-types";
//Router

import { Outlet } from "react-router-dom";
//Components

import {
  Footer,
  Header
} from "layout-components";

const Main = props => {
  const { location, className } = props;

  return (
    <div className="bg-white h-full w-full">
      <Header />
      <main className={"flex items-center w-100 flex-col min-h-[calc(100dvh-4rem-1.5rem-19rem-4rem)] 2xl:min-h-[calc(100dvh-6rem-2.25rem-18rem-8rem)] " + className}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  location: PropTypes.object,
  className: PropTypes.string
}

export default Main;
