//React

import React from "react";

function Impressum() {

    React.useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "instant"
        });
        document.title = process.env.REACT_APP_NAME + ' - Impresszum';
    }, []);

    return (
        <section id="impresszum" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
            <h2 className="font-medium text-center text-20 2xl:text-40 mb-10">Impresszum</h2>
            <ul className="flex flex-col gap-2 text-15">
                <li className="font-medium">
                    <span className="font-semibold">Üzemeltető:</span> Czutor Emil József E.V.
                </li>
                <li className="font-medium">
                    <span className="font-semibold">Székhely:</span>  2600. Vác, Sas utca 1. 2em 2 ajtó
                </li>
                <li className="font-medium">
                    <span className="font-semibold">Ügyfélszolgálat:</span> info@rajtautosiskola.hu; +36 70 4020 112
                </li>
                <li className="font-medium">
                    <span className="font-semibold">Adószám:</span> 55562324-1-51
                </li>
                <li className="font-medium">
                    <span className="font-semibold">Nyilvántartási szám:</span> 54262375
                </li>
                <li className="font-medium">
                    <span className="font-semibold">Nyilvántartó hatóság:</span> Építési és Közlekedési Minisztérium
                </li>
                <li className="font-medium">
                    <span className="font-semibold">Tárhelyszolgáltató:</span> DotRoll Kft., 1148 Budapest, Fogarasi út 3-5., +36 1 432 3232,  support@dotroll.com
                </li>
            </ul>
        </section>
    );
}

export default Impressum;
