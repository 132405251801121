//React

import React from "react";
import axios from 'axios';
//Router

import { NavLink } from "react-router-dom";
//Components

import toast from 'react-hot-toast';
//Helpers

import {
  telephoneRegex,
  emailRegex
} from "helpers/helpers";
import { ErdeklodoModel } from "helpers/models/erdeklodo";

function AboutUs() {



  //Form
  const [form, setForm] = React.useState({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    message: ""
  })

  const [loading, setLoading] = React.useState(false);

  const [validEmail, setValidEmail] = React.useState(true);
  const [validPhone, setValidPhone] = React.useState(true);

  React.useEffect(() => {
    document.title = process.env.REACT_APP_NAME + ' - Rólunk';
  }, []);

  const validLastName = !(form.lastName === null || form.lastName === undefined || form.lastName == "");
  const validFirstName = !(form.firstName === null || form.firstName === undefined || form.firstName == "");
  const validEmailField = !(form.email === null || form.email === undefined || form.email == "");
  const validPhoneNumber = !(form.phoneNumber === null || form.phoneNumber === undefined || form.phoneNumber == "");
  const validMessage = !(form.message === null || form.message === undefined || form.message == "");

  const validateForm = validEmail && validPhone && validLastName && validFirstName && validEmailField && validPhoneNumber && validMessage;

  const handleChange = (e) => {
    const enteredValue = e.target.value;
    const inputType = e.target.type;
    const name = e.target.name;
    if (inputType === "email") {
      setValidEmail(emailRegex.test(enteredValue) || (enteredValue === null || enteredValue === undefined || enteredValue == ""));
    }
    if (name === "phoneNumber") {
      setValidPhone(telephoneRegex.test(enteredValue) || (enteredValue === null || enteredValue === undefined || enteredValue == ""));
    }

    setForm(prevState => ({
      ...prevState,
      [name]: enteredValue
    }));
  }

  function onSubmit(event) {
    event.preventDefault()

    setLoading(true);
    const model = new ErdeklodoModel(form.lastName, form.firstName, form.email, form.phoneNumber, form.message);

    axios.post(`${process.env.REACT_APP_API_URL}/erdeklodos`, { data: model }, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_CONTACT_TOKEN}` } })
      .then(res => {
        toast.success('Az érdeklődés elküldve!', { duration: 5000 });
        setForm({
          lastName: "",
          firstName: "",
          email: "",
          phoneNumber: "",
          message: ""
        });
      }).catch(err => {
        console.error(err);
        toast.error('Az érdeklődést nem sikerült elküdeni!', { duration: 5000 });
      }).finally(() => {
        setLoading(false);
      });

  }

  return (
    <>
      <section id="main-banner" className="w-full mb-4 pl-0 pr-0 lg:pl-6 lg:pr-6 relative">
        <img src="/assets/images/main-banner.png" alt="main-banner"
          className="h-104 lg:h-186 w-full -mb-40 lg:rounded-3.125 rounded-none object-cover" />
        <h1 className="text-25 lg:text-64 font-normal">
          A legjobb pozícióból...
        </h1>
      </section>
      <section id="main-cards" className="z-50 flex flex-col gap-5 items-center pl-6 pr-6 w-full lg:flex-row lg:justify-between lg:items-end lg:pl-0 lg:pr-0 max-w-viewport">
        <div className="main-card bg-active rounded-1.875 pl-2 pr-2 w-full max-w-[36rem]">
          <h2 className="font-normal text-20 lg:text-40 text-center mt-10 mb-16">
            Vezess <span className=" underline">magabiztosan</span><br /> friss jogsival!
          </h2>
          <h4 className="text-black font-normal text-17 lg:text-20 mb-4 text-center">
            Nálunk mindent megtalálsz, hogy biztosan célba érj:
          </h4>
          <div className="mt-8 mb-8 flex flex-col justify-center items-center gap-4 md:hidden">
            <p className="note text-17 lg:text-20 font-medium">ügyfélközpontúság</p>
            <p className="note text-17 lg:text-20 font-medium">fiatal járműpark</p>
            <p className="note text-17 lg:text-20 font-medium">széleskörű szakmai tudás</p>
            <p className="note text-17 lg:text-20 font-medium">automataváltós autó</p>
            <p className="note text-17 lg:text-20 font-medium">rugalmasság</p>
            <p className="note text-17 lg:text-20 font-medium">e-learning oktatás</p>
            <p className="note text-17 lg:text-20 font-medium">angol-német oktatás</p>
            <p className="note text-17 lg:text-20 font-medium">állandó kedvezmények</p>
          </div>
          <div className="md:grid mt-8 mb-8 xl:mb-16 justify-center items-center gap-8 hidden">
            <p className="note text-17 lg:text-20 font-medium float-end ml-auto">ügyfélközpontúság</p>
            <p className="note text-17 lg:text-20 font-medium mr-auto">fiatal járműpark</p>
            <p className="note text-17 lg:text-20 font-medium col-span-2 m-auto">széleskörű szakmai tudás</p>
            <p className="note text-17 lg:text-20 font-medium ml-auto">automataváltós autó</p>
            <p className="note text-17 lg:text-20 font-medium mr-auto">rugalmasság</p>
            <p className="note text-17 lg:text-20 font-medium ml-auto">e-learning oktatás</p>
            <p className="note text-17 lg:text-20 font-medium mr-auto">angol-német oktatás</p>
            <p className="note text-17 lg:text-20 font-medium col-span-2 m-auto">állandó kedvezmények</p>
          </div>
          <p className="text-15 lg:text-17 font-normal text-center mt-4 mb-10">
            Célunk, hogy tanulóinkat maximálisan felkészítsük<br /> a biztonságos és tudatos közlekedésre.
          </p>
        </div>
        <div className="main-card card-contact bg-primary rounded-1.875 pl-4 pr-4 lg:pr-10 lg:pl-10 w-full lg:w-3/4 relative max-w-[30rem]">
          {loading && <>
            <div className="absolute flex items-center justify-center w-full h-full bg-secondary opacity-30 rounded-1.875 pl-4 pr-4 md:pl-8 md:pr-8 lg:pl-4 lg:pr-4 left-0 z-10" />
            <div className="absolute flex items-center justify-center w-full h-full rounded-1.875 pl-4 pr-4 md:pl-8 md:pr-8 lg:pl-4 lg:pr-4 left-0 z-10">
              <svg className="w-12 h-12 text-active animate-spin z-20" viewBox="0 0 64 64" fill="none"
                xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path
                  d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                  stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                  stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" className="text-gray-900">
                </path>
              </svg>
            </div>
          </>
          }
          <h2 className="font-normal text-20 lg:text-40 mt-6 mb-6 text-center">Kérdésed van?</h2>
          <form className="flex flex-col gap-4">
            <input disabled={loading} className="h-8 lg:h-9 shadow-sm" type="text" id="flast_name" name="lastName" placeholder="Vezetéknév*" value={form?.lastName} required onChange={handleChange} />
            <input disabled={loading} className="h-8 lg:h-9 shadow-sm" type="text" id="first_name" name="firstName" placeholder="Keresztnév*" value={form?.firstName} required onChange={handleChange} />
            <input disabled={loading} className={`h-8 lg:h-9 shadow-sm ${!validEmail ? "not-valid" : ""}`} type="email" id="email" name="email" placeholder="E-mail cím*" value={form?.email} onChange={handleChange} required />
            {/*validEmail ? null : <span className="text-red-600 text-12">Kérlek adj meg egy valódi e-mail címet!</span>*/}
            <input disabled={loading} className={`h-8 lg:h-9 shadow-sm ${!validPhone ? "not-valid" : ""}`} type="text" id="phone_number" name="phoneNumber" placeholder="Telefonszám*" value={form?.phoneNumber} onChange={handleChange} required />
            {/*validPhone ? null : <span className="text-red-600 text-12">Kérlek adj meg egy valódi telefonszámot!</span>*/}
            <textarea disabled={loading} className="shadow-sm" rows="8" type="text" id="message" name="message" placeholder="Üzenet*" value={form?.message} onChange={handleChange} required />
            <div className="flex flex-row justify-between">
              <p className="font-normal text-10 text-black ml-2 xl:ml-4">*Kötelezően kitöltendő</p>
              <button disabled={!validateForm || loading} id="contact-send-button" onClick={(event) => onSubmit(event)} className="end-icon bg-black text-white pl-5 pr-5 pt-3 pb-3 w-36 disabled:bg-secondary disabled:opacity-35 enabled:hover:shadow-xl enabled:hover:bg-white enabled:hover:text-black">
                <p className="font-medium text-10 lg:text-15">Küldés</p>
                <img src="/assets/logos/white-chevron-right.png" alt="chevron-right" className="w-auto h-2 lg:h-3" />
              </button>
            </div>
          </form>
          <a href="/documents/ASZF-2.pdf" download className="flex flex-row gap-2 items-center hover:text-secondary text-black">
            <p className="text-12 font-medium">Adatkezelési tájékoztató</p>
          </a>
          <div className="flex items-start gap-2 mb-10 mt-4 lg:gap-4">
            <img src="/assets/logos/check-logo.png" alt="check-img" className=" h-6 w-auto" />
            <p className="font-normal text-15 lg:text-17">
              Hozzájárulok az adatkezelési tájékoztató szerinti adatkezeléshez.
            </p>
          </div>

        </div>
      </section>
      <section id="discount-banner" className="relative mt-20 mb-20 min-h-[20rem]">
        <img id="discount-banner-img" src="/assets/images/car-dashboard.png" alt="discount-banner" className="min-h-[20rem] w-full object-cover max-w-viewport" />
        <div className="p-8 absolute w-full bottom-16">
          <h2 className="font-medium text-20 lg:text-40 text-white">
            Aktuális akcióink
          </h2>
          <p className="text-white font-medium text-17 lg:text-20 mt-4 mb-6">
            Időszakos kedvezmények mellett,<br /> állandó egyedi díjszabás hivatásosoknak.
          </p>
          <NavLink to="/informaciok/akciok" className="end-icon bg-transparent border border-white text-white w-72 pl-5 pr-5 pt-1 pb-1 hover:bg-white hover:text-black hover:shadow-xl">
            <p className="font-semibold text-10 lg:text-15">Megnézem</p>
            <img src="/assets/logos/colored-circle-chevron-right.png" alt="chevron-right" className="h-6 lg:h-10 w-auto" />
          </NavLink>
          {/*<button className="end-icon bg-transparent border border-white text-white w-72 lg:w-98 pl-5 pr-5 pt-3 pb-3">
            <p className="font-semibold text-10 lg:text-15">Megnézem</p>
            <img src="/assets/logos/colored-circle-chevron-right.png" alt="chevron-right" className="h-6 lg:h-12 w-auto" />
          </button>*/}
        </div>
      </section>
      <section id="offers" className="bg-black w-full pt-12 pb-12">
        <div className="offer-cards-wrapper m-auto pl-6 pr-6 max-w-viewport lg:pr-0 lg:pl-0">
          <h2 className=" text-white font-medium mb-8 text-20 lg:text-40">Kiemelt ajánlataink</h2>
          <div className="flex items-center gap-4 flex-col justify-center lg:flex-row lg:pl-0 lg:pr-0">
            <div className="offer-card bg-white w-full flex flex-col justify-between pl-14 pr-6 pt-6 pb-6 rounded-1.625">
              <div>
                <img src="/assets/logos/circle-chevron-right.png" alt="chevron-right" className="float-right h-10 w-auto" />
                <img src="/assets/logos/book.png" alt="book-img" className="mt-12 mb-4 h-14 w-auto" />
                <h4 className="font-extrabold text-17 lg:text-25 mb-2">Online elméleti képzés</h4>
                <ul>
                  <li className="flex flex-row gap-2 items-center">
                    <img src="/assets/logos/dot.png" alt="dot-img" />
                    <p className="text-14 lg:text-20 text-secondary font-medium">Azonnali kezdés</p>
                  </li>
                  <li className="flex flex-row gap-2 items-center">
                    <img src="/assets/logos/dot.png" alt="dot-img" />
                    <p className="text-14 lg:text-20 text-secondary font-medium">Otthonról elvégezhető KRESZ tanfolyam</p>
                  </li>
                  <li className="flex flex-row gap-2 items-center">
                    <img src="/assets/logos/dot.png" alt="dot-img" />
                    <p className="text-14 lg:text-20 text-secondary font-medium">Teljeskörő online ügyintézés</p>
                  </li>
                  <li className="flex flex-row gap-2 items-center h-[1.4rem]">
                  </li>
                </ul>
              </div>
              <NavLink id="offer_1_button" to="/kapcsolat" className="text-black bg-transparent end-icon border border-black w-56 pl-5 pr-5 pt-2 pb-2 mb-4 mt-4 hover:bg-black hover:text-white hover:shadow-xl">
                <p className="font-medium text-10 lg:text-15">Jelentkezem</p>
                <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 lg:h-3" />
              </NavLink>
              {/*<button className="text-black bg-transparent end-icon border border-black w-56 2xl:w-80 pl-5 pr-5 pt-3 pb-3 mb-4 mt-4">
                <p className="font-medium text-10 lg:text-15">Jelentkezem</p>
                <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 lg:h-4" />
              </button>*/}
            </div>
            <div className="offer-card w-full flex flex-col justify-between bg-primary pl-14 pr-6 pt-6 pb-6 rounded-1.625">
              <div>
                <img src="/assets/logos/circle-chevron-right.png" alt="chevron-right" className="float-right h-10 w-auto" />
                <img src="/assets/logos/wheel.png" alt="wheel-img" className="mt-12 mb-4 h-14 w-auto" />
                <h4 className="font-extrabold text-17 lg:text-25 mb-2">Gyakorlati órák</h4>
                <ul>
                  <li className="flex flex-row gap-2 items-center">
                    <img src="/assets/logos/dot.png" alt="dot-img" />
                    <p className="text-14 lg:text-20 font-medium">profi oktatók</p>
                  </li>
                  <li className="flex flex-row gap-2 items-center">
                    <img src="/assets/logos/dot.png" alt="dot-img" />
                    <p className="text-14 lg:text-20 font-medium">"B" kategóriában</p>
                  </li>
                  <li className="flex flex-row gap-2 items-center">
                    <img src="/assets/logos/dot.png" alt="dot-img" />
                    <p className="text-14 lg:text-20 font-medium">hétvégi felár nélkül</p>
                  </li>
                </ul>
              </div>
              <NavLink id="offer_2_button" to="/kapcsolat" className="text-black bg-transparent end-icon border border-black w-56 pl-5 pr-5 pt-2 pb-2 mb-4 mt-4 hover:bg-black hover:text-white hover:shadow-xl">
                <p className="font-medium text-10 lg:text-15">Érdekel</p>
                <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 lg:h-3" />
              </NavLink>
              {/*<button className="text-black bg-transparent end-icon border border-black w-56 lg:w-80 pl-5 pr-5 pt-3 pb-3 mb-4 mt-4">
                <p className="font-medium text-10 lg:text-15">Érdekel</p>
                <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 lg:h-4" />
              </button>*/}
            </div>
          </div>
        </div>
      </section>
      <section id="foreign-languages" className="bg-white w-full pt-12 pb-12 mt-8 mb-8">
        <div className=" max-w-viewport m-auto pl-6 pr-6 flex flex-col gap-8 items-center lg:items-end lg:flex-row lg:pl-0 lg:pr-0 lg:justify-between">
          <div>
            <img id="flags-img" src="/assets/logos/flags.png" alt="flags" className="h-10 w-auto m-auto lg:m-0" />
            <h3 className="mt-6 font-normal text-20 lg:text-35 text-center lg:text-left">
              Oktatás idegen nyelven is!<br /> Amennyiben <span className=" underline">külföldi vagy,</span><br /> autósiskolánk akkor is szeretettel vár!
            </h3>
          </div>
          <img id="foreign-img" src="/assets/images/foreign-languages.png" alt="foreign-img" className="lg:h-56 max-h-56 h-auto w-auto" />
        </div>
      </section>
      <section id="state-discount" className="pr-6 pl-6 lg:pl-20 lg:pr-20 text-center bg-card2 pt-12 pb-12 rounded-0.75 w-full max-w-viewport">
        <h3 className=" font-bold text-20 lg:text-35 mb-8">
          Állami kedvezmény
        </h3>
        <p className="font-normal text-19">
          20 év alattiak, illetve különböző gyermekgondozási ellátásban részesülők számára állami támogatás igényelhető a közlekedési alapismeretek vizsga, illetve a hozzá kapcsolódó közlekedési alapismeretek tanfolyam díjához.
        </p>
        <a id="offer_1_button" href="https://www.allamkincstar.gov.hu/egyeb-lakossagi-ugyek/Egyeb_juttatasok/a-kozlekedesi-alapismeretek-tanfolyam-es-vizsga-dijahoz-nyujtott-tamogatas" target="_blank" className="text-black bg-transparent end-icon border border-black w-32 pl-5 pr-5 pt-2 pb-2 mb-4 mt-4 hover:bg-black hover:text-white hover:shadow-xl m-auto">
          <p className="font-medium text-10 lg:text-15">Érdekel</p>
          <img src="/assets/logos/black-chevron-right.png" alt="chevron-right" className="w-auto h-2 lg:h-3" />
        </a>
      </section>
    </>
  );
}

export default AboutUs;
