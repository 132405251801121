//React

import React from "react";
//Router

import { NavLink } from "react-router-dom";

const Footer = props => {
  const { } = props;

  return (
    <footer className="mt-16 2xl:mt-32 h-[19rem] 2xl:h-[18rem]">
      <div className="footer-container pt-6 pb-6">
        <div className="top-block">
          <div className="flex flex-col gap-4">
            <img id="footer-logo" src="/assets/logos/black-logo.png" alt="black-logo" className="h-6 2xl:h-10" />
            <div id="socials" className="2xl:hidden flex flex-col justify-center items-start gap-4">
              <a href="https://www.facebook.com/rajtautosiskola" target="_blank" className="font-medium social-wrapper">
                <div className="w-4 mr-1">
                  <img src="/assets/logos/facebook.png" alt="facebook-logo" className="ml-auto" />
                </div>
                <p className="text-12 text-secondary">Facebook</p>
              </a>
              <a href="https://www.instagram.com/rajtautosiskola" target="_blank" className="font-medium social-wrapper">
                <div className="w-4 mr-1">
                  <img src="/assets/logos/instagram.png" alt="instagram-logo" className="ml-auto" />
                </div>
                <p className="text-12 text-secondary">Instagram</p>
              </a>
              <a href="https://www.tiktok.com/@rajt.autosiskola" target="_blank" className="font-medium social-wrapper">
                <div className="w-4 mr-1">
                  <img src="/assets/logos/tiktok.png" alt="tiktok-logo" className="ml-auto" />
                </div>
                <p className="text-12 text-secondary">Tiktok</p>
              </a>
            </div>
          </div>
          <div className="footer-points-container">
            <NavLink to="/kapcsolat" className="bg-primary w-32 2xl:w-36 pt-2 pb-2 2xl:pt-3 2xl:pb-3 rounded-full uppercase text-center hover:bg-transparent hover:shadow-lg">
              <p className="font-bold text-10 2xl:text-12">
                JELENTKEZEM
              </p>
            </NavLink>
            <ul id="footer-nav" className="flex flex-col 2xl:flex-row justify-center items-end 2xl:items-center gap-2 2xl:gap-8">
              <li className="font-normal text-12 2xl:text-14 text-nowrap"><NavLink to="/kapcsolat">Kapcsolat</NavLink></li>
              <li className="font-normal text-12 2xl:text-14 text-nowrap"><NavLink to="/informaciok/dokumentumok">Adatkezelési tájékoztató</NavLink></li>
              <li className="font-normal text-12 2xl:text-14 text-nowrap"><NavLink to="/informaciok/impresszum">Impresszum</NavLink></li>
              {/*<li className="font-normal text-14 2xl:text-14 text-nowrap"><NavLink to="/informaciok/dokumentumok">GYIK</NavLink></li>*/}
              <li className="font-normal text-12 2xl:text-14 "><NavLink to="/informaciok/dokumentumok">ÁSZF</NavLink></li>
              <li className="font-normal text-12 2xl:text-14 "><a target="_blank" href="https://vizsgakozpont.hu/kereses/VSM">VSM</a></li>
              <li className="font-normal text-12 2xl:text-14 "><a target="_blank" href="https://vizsgakozpont.hu/kereses/k%C3%A9pz%C3%A9si%20%C3%B3rasz%C3%A1m">ÁKÓ</a></li>
            </ul>
          </div>
        </div>
        <div className="bottom-block mt-4 2xl:mt-16">
          <div id="socials" className="hidden 2xl:flex flex-row justify-start items-center h-full gap-8">
            <a href="https://www.facebook.com/rajtautosiskola" target="_blank" className="font-medium social-wrapper">
              <div className="w-6 mr-2">
                <img src="/assets/logos/facebook.png" alt="facebook-logo" className="h-5 w-auto ml-auto" />
              </div>
              <p className="text-14 text-secondary">Facebook</p>
            </a>
            <a href="https://www.instagram.com/rajtautosiskola" target="_blank" className="font-medium social-wrapper">
              <div className="w-6 mr-2">
                <img src="/assets/logos/instagram.png" alt="instagram-logo" className="h-5 w-auto ml-auto" />
              </div>
              <p className="text-14 text-secondary">Instagram</p>
            </a>
            <a href="https://www.tiktok.com/@rajt.autosiskola" target="_blank" className="font-medium social-wrapper">
              <div className="w-6 mr-2">
                <img src="/assets/logos/tiktok.png" alt="tiktok-logo" className="h-5 w-auto ml-auto" />
              </div>
              <p className="text-14 text-secondary">Tiktok</p>
            </a>
          </div>
          <div className="flex flex-row justify-between w-full 2xl:w-fit 2xl:justify-start gap-2 2xl:gap-4 items-center">
            <div className="flex flex-col gap-2 2xl:hidden">
              <p className="footer-badge font-medium text-10 2xl:text-14 none 2xl:block">+36 70 4020 112</p>
              <p className="footer-badge font-medium text-10 2xl:text-14 none 2xl:block">info@rajtautosiskola.hu</p>
            </div>
            <p className=" hidden footer-badge font-medium text-10 2xl:text-14 none 2xl:block">+36 70 4020 112</p>
            <p className="hidden footer-badge font-medium text-10 2xl:text-14 none 2xl:block">info@rajtautosiskola.hu</p>
            <p className="footer-badge font-medium text-10 2xl:text-14 2xl:block">2600 Vác, Görgey Artúr utca 1.</p>
          </div>
        </div>
        <img src="/assets/logos/line.png" alt="line-img" className="mt-4" />
        <p id="copyright" className=" font-medium text-10 ml-auto mt-4 text-secondary">®All Rights Reserved 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
