//React

import React from "react";

function Blog() {

  React.useEffect(() => {
    document.title = process.env.REACT_APP_NAME + ' - Blog';
  }, []);

    return (
      <>
          <h1 className="text-3xl">Blog</h1>
      </>
    );
  }
  
  export default Blog;
  