//React

import React from "react";
//Material UI

import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
} from "@mui/material";
//Icons

import CloseIcon from '@mui/icons-material/Close';


function PopUp() {

    const [open, setOpen] = React.useState(true);

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={() => setOpen(false)}
        >
            <div className=" shadow-lg">
                <Box display="flex" alignItems="center" justifyContent="flex-end" padding="0.5rem">
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        size="small"
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </div>
            <DialogContent>
                <img id="open-banner" src="/assets/images/nyitvatartas_banner.jpg" />
            </DialogContent>
        </Dialog>
    )
}

export default PopUp;