//React

import React from "react";
//Material UI

import {
  IconButton,
  Dialog,
  DialogContent,
  Box
} from "@mui/material";
//Icons

import CloseIcon from '@mui/icons-material/Close';
//Components
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function Instructors() {

  React.useEffect(() => {
    document.title = process.env.REACT_APP_NAME + ' - Oktatók';
  }, []);

  const [dialog, setDialog] = React.useState({ open: false, content: null, array: [] });

  return (
    <section id="instructors">
      <div className="description-block mb-20 flex flex-row gap-4 lg:pr-6 lg:pl-6 2xl:pr-0 2xl:pl-0">
        <div className="first-col w-full 2xl:w-1/2">
          <div className="first-row bg-primary rounded-none lg:rounded-1.625 mb-8 p-8">
            <h2 className="font-bold text-20 2xl:text-30">Hiszünk benne...</h2>
            <p className="text-12 2xl:text-15">
              ...hogy mindenki meg tud tanulni vezetni, csak a megfelelő segítség kell hozzá.
            </p>
            <p className=" mt-5 2xl:mt-10 text-12 2xl:text-15">
              A Rajt Autósiskolánál kiemelt figyelmet fordítunk arra, hogy oktatóink kellő empátiával,
              türelemmel és rugalmasan álljanak a tanulókhoz és magas fokú szakmai hozzáértéssel sziklaszilárd tudást biztosítsanak a biztonságos és tudatos vezetéshez.
            </p>
            <p className="mt-5 2xl:mt-10 text-12 2xl:text-15">
              Iskolavezetőként és aktív rendőrként nagyon fontosnak tartom, hogy át tudjuk adni azt a gyakorlati tudást a tanulóinknak,
              hogyan tudják elkerülni a vezetéstechnikai hibákból fakadó - sok esetben tragikus kimenetelű - baleseteket.
            </p>
          </div>
          <div className="second-row 2xl:w-80% m-auto flex flex-col items-center gap-4 w-full pr-6 pl-6 2xl:pr-0 2xl:pl-0">
            {/*<img src="/assets/images/instructors.png" alt="instructors" className="h-auto 2xl:hidden block max-h-52" />*/}
            <p className="font-medium text-12 2xl:text-15">
              Autósiskolánk „B” kategóriás autóvezetői jogosítvány megszerzéséhez szükséges képzéssel és teljeskörű ügyintézéssel foglalkozik.
              Az elméleti képzés online tanfolyam keretében történik. Egyedi igény esetén kontaktórás elméleti oktatás is elérhető. Az elméleti és gyakorlati vizsgákat a tanuló igényei szerint szervezzük.
            </p>

          </div>
        </div>
        <div className="second-col hidden lg:block 2xl:w-1/2">
          <img src="/assets/images/instructors.png" alt="instructors" className="h-full object-cover rounded-1.625" />
        </div>
      </div>
      <div className="instructors-block pr-6 pl-6 2xl:pr-0 2xl:pl-0">
        <div className="instructor flex flex-col lg:flex-row justify-between items-center 2xl:items-end gap-8">
          <div className="lg:w-150">
            <h4 className="font-semibold mb-2 text-17 2xl:text-25">Czutor Emil</h4>
            <p className="font-medium text-12 2xl:text-15 mb-4">Fiat</p>
            <p className="text-secondary text-12">
              2012 óta főállású rendőrként dolgozom Vácon, közel 4 évet baleseti helyszínelőként tevékenykedtem. Sok tapasztalatot szereztem azzal kapcsolatban, hogy egy pillanatnyi figyelmetlenség mekkora gondot okozhat gépjármű vezetés során.
              2019-ben okleveles gépjárművezető szakoktató lettem, még több tapasztalatot szereztem azzal kapcsolatban, hogy mire van szüksége egy tanulónak, ahhoz, hogy meg tudja állni a helyét szinte bármilyen közlekedési szituációban és balesetmentesen tudja folytatni az útját.
            </p>
            <p className="text-secondary text-12 mt-4">
              A Rajt Autósiskola ezt a szemlélet követi majd, annak érdekében, hogy tanulóink a legjobb pozícióból indulhassanak és megkapjanak minden segítséget ahhoz, hogy sikeresen beérjenek a célba: ahol a biztonságos és balesetmentes közlekedéshez szükséges tudás várja őket.
            </p>
            <div className="instructor-badges">
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">B kategória - személygépkocsi</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">manuális váltós</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">benzin</p>
            </div>
          </div>
          <div className="relative">
            <img src="/assets/images/emil/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "emil", array: [1] })} />
            {/*<img src="/assets/images/emil/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/emil/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/emil/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/emil/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />*/}
          </div>
        </div>
        <div className="instructor flex flex-col lg:flex-row justify-between items-center 2xl:items-end gap-8">
          <div className="relative hidden lg:block">
            <img src="/assets/images/laszlo/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "laszlo", array: [1, 2, 3, 4, 5] })} />
            <img src="/assets/images/laszlo/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/laszlo/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/laszlo/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/laszlo/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />
          </div>
          <div className="lg:w-150">
            <h4 className="font-semibold mb-2 text-17 2xl:text-25">Czibere László</h4>
            <p className="font-medium text-12 2xl:text-15 mb-4">BMW</p>
            <p className=" text-secondary text-12">
              2019 óta foglalkozom gépjármű oktatással. Teljes elhivatottsággal tudom átadni a szerzett tudásomat és gyakorlatomat a nálam tanulóknak.
              Türelemmel, megértéssel és jó problémamegoldó képességgel rendelkezem. A cél közös, ezért fontos a folyamatos kommunikáció, együttműködés mindkét fél (oktató-tanuló) részéről.
              „Mindenki aki besétál az életedbe, megtanít valamire. Még akkor is tanítanak Téged, ha az agyadra mennek, mert ilyenkor megmutatják határaidat.” (Andrew Matthews)
            </p>
            <p className="text-secondary text-12 mt-4">
              Mottóm: „A kiválóság azt jelenti, büszke vagy magadra, boldog vagy magaddal, tudod, hogy megdolgoztál valamiért és mindent megtettél. Ez maga a kiválóság.” (Shawn Johnson) Remélem, hamarosan találkozunk a RAJT Autósiskolánál!
            </p>
            <div className="instructor-badges">
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">B kategória - személygépkocsi</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">manuális váltós</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">dízel</p>
            </div>
          </div>
          <div className="relative lg:hidden block">
            <img src="/assets/images/laszlo/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "laszlo", array: [1, 2, 3, 4, 5] })} />
            <img src="/assets/images/laszlo/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/laszlo/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/laszlo/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/laszlo/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />
          </div>
        </div>
        <div className="instructor flex flex-col lg:flex-row justify-between items-center 2xl:items-end gap-8">
          <div className="lg:w-150">
            <h4 className="font-semibold mb-2 text-17 2xl:text-25">Nagy Tibor</h4>
            <p className="font-medium text-12 2xl:text-15 mb-4">Toyota</p>
            <p className="text-secondary text-12">
              Öt éve foglalkozom autóvezetés oktatással. A tanuló-, és gyakorlóvezetők oktatása számomra nem csak munka, hanem hobbi is. Munkámat pedagógiai és autószerelői végzettségem, valamint a közel harmincéves, több kategóriában megszerzett gyakorlati tapasztalataim segítik.
            </p>
            <p className="text-secondary text-12 mt-4">
              Célom, hogy a tanulók a dinamikus forgalom elvárásainak megfeleljenek, abban önállóan, magabiztosan és biztonságosan közlekedjenek. Fő erényem a végtelen türelem és kitartás.
              Közös célunk, a biztos vezetési tudás és a jogosítvány megszerzése! Nálam a feszültség nem generálódik, hanem oldódik. Amennyiben felkeltettem érdeklődésedet, várlak tanulómnak!
            </p>
            <div className="instructor-badges">
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">B kategória - személygépkocsi</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">automata váltós</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">hibrid</p>
            </div>
          </div>
          <div className="relative">
            <img src="/assets/images/tibor/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "tibor", array: [1, 2, 3, 4, 5] })} />
            <img src="/assets/images/tibor/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/tibor/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/tibor/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/tibor/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />
          </div>
        </div>
        <div className="instructor flex flex-col lg:flex-row justify-between items-center 2xl:items-end gap-8">
          <div className="relative hidden lg:block">
            <img src="/assets/images/krisztina/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "krisztina", array: [1, 2, 3, 4, 5] })} />
            <img src="/assets/images/krisztina/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/krisztina/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/krisztina/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/krisztina/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />
          </div>
          <div className="lg:w-150">
            <h4 className="font-semibold mb-2 text-17 2xl:text-25">Surányiné Csefkó Krisztina</h4>
            <p className="font-medium text-12 2xl:text-15 mb-4">Renault</p>
            <p className="text-secondary text-12">
              2004. szeptembere óta - tehát már 20 éve - gépjármű szakoktatóként tevékenykedek. A tanulók visszajelzései alapján könnyen szót értek a fiatalokkal való kommunikációban. Hiszek abban, hogy minden korosztálynak a megfelelő módon kell segíteni, hogy a saját képességeit maximálisan ki tudja bontakoztatni, illetve akinél szükséges, az önbizalmát felépíteni!
            </p>
            <p className="text-secondary text-12 mt-4">
              Célom, hogy minden tanulóm magabiztos, használható vezetési tudás birtokában, önálló vezetővé váljon! Amennyiben szívesen tanulnál tőlem, várlak szeretettel!
            </p>
            <div className="instructor-badges">
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">B kategória - személygépkocsi</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">manuális váltós</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">dízel</p>
            </div>
          </div>
          <div className="relative lg:hidden block">
            <img src="/assets/images/krisztina/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "krisztina", array: [1, 2, 3, 4, 5] })} />
            <img src="/assets/images/krisztina/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/krisztina/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/krisztina/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/krisztina/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />
          </div>
        </div>
        <div className="instructor flex flex-col lg:flex-row justify-between items-center 2xl:items-end gap-8">
          <div className="lg:w-150">
            <h4 className="font-semibold mb-2 text-17 2xl:text-25">Kovács Erik</h4>
            <p className="font-medium text-12 2xl:text-15 mb-4">Skoda</p>
            <p className="text-secondary text-12">
              15 éve hivatásszerűen foglalkozom a közlekedésbiztonság javításával, 2023 óta pedig hivatásos gépjárművezető oktatóként dolgozom. Mindegyik nagykategóriás vezetői engedéllyel rendelkezem, és aktívan a nemzetközi személyszállítás területén is tevékenykedem.
            </p>
            <p className="text-secondary text-12 mt-4">
              Célom, hogy a tanulókat a biztonságos vezetésre felkészítsem, és az órákat mindig nyugodt légkörben tartsam. Az egyik legfontosabb szemléletmódom, hogy a felmerülő problémákat mielőbb megbeszéljük és hatékonyan megoldjuk, hogy a tanulók vezetéstechnikája ezáltal gyorsabban fejlődjön.
            </p>
            <div className="instructor-badges">
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">B kategória - személygépkocsi</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">manuális váltós</p>
              <p className="font-medium text-secondary text-center text-10 rounded-1.25 border-secondary border pl-3 pr-3 pt-1 pb-1 2xl:pl-6 2xl:pr-6 2xl:pb-2 2xl:pt-2">dízel</p>
            </div>
          </div>
          <div className="relative">
            <img src="/assets/images/erik/1.jpg" className="z-50 relative hover:outline-none hover:ring-2 hover:ring-primary hover:cursor-pointer" alt="instructor" onClick={() => setDialog({ open: true, content: "erik", array: [1, 2, 3, 4, 5] })} />
            <img src="/assets/images/erik/2.jpg" className="z-40 absolute bottom-0 left-0 rotate-3" alt="instructor" />
            <img src="/assets/images/erik/3.jpg" className="z-30 absolute bottom-0 left-0 rotate-6" alt="instructor" />
            <img src="/assets/images/erik/4.jpg" className="z-20 absolute bottom-0 left-0 rotate-12" alt="instructor" />
            <img src="/assets/images/erik/5.jpg" className="z-10 absolute bottom-0 left-0 rotate-24" alt="instructor" />
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={dialog.open}
        onClose={() => setDialog(prevState => ({ ...prevState, open: false }))}
      >
        <div className="shadow-lg">
          <Box display="flex" justifyContent="space-between" padding="0.75rem">
            <p className=" font-medium 2xl:text-17">Galéria</p>
            <IconButton size="small" onClick={() => setDialog(prevState => ({ ...prevState, open: false }))}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </div>

        <DialogContent>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={true}
            modules={[Pagination]}
          >
            {dialog.array.map((element, index) =>
              <SwiperSlide index={index}>
                <img src={`/assets/images/${dialog.content}/${index + 1}.jpg`} className="rounded-lg" alt="instructor" />
              </SwiperSlide>
            )}
          </Swiper>
        </DialogContent>
      </Dialog>
    </section>
  );
}

export default Instructors;
