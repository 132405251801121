//React

import React from "react";
//Router

import {
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
//Layouts

import {
    Main as MainLayout
} from "layout-blueprints";
//Views

import {
    AboutUs as AboutUsView,
    Blog as BlogView,
    Contacts as ContactsView,
    Infos as InfosView,
    Psychologist as PsychologistView,
    Documents as DocumentsView,
    Discounts as DiscountsView,
    Instructors as InstructorsView,
    Prices as PricesView,
    GetLicence as GetLicenceView,
    PartnerCard as PartnerCardView,
    GiftCard as GiftCardView,
    Impressum as ImpressumView,
    Kabala as KabalaView,
    NotFound as NotFoundView,
    FirstAid as FirstAidView
} from "views"



function AppRoutes(props) {
    let location = useLocation();

    return (
        <div className="app-wrapper">
            <Routes>
                <Route element={<MainLayout location={location} />}>
                    <Route path="/" element={<AboutUsView />} />
                    <Route path="/blog" element={<BlogView />} />
                    <Route path="/kapcsolat" element={<ContactsView />} />

                    <Route path="/oktatoink" element={<InstructorsView />} />
                    <Route path="/informaciok/dokumentumok" element={<DocumentsView />} />
                    <Route path="/informaciok/ajandekutalvany" element={<GiftCardView />} />
                    <Route path="/informaciok/akciok" element={<DiscountsView />} />
                    <Route path="/araink" element={<PricesView />} />
                    <Route path="/informaciok/szerezz-jogsit" element={<GetLicenceView />} />
                    <Route path="/informaciok/partnerek" element={<PartnerCardView />} />
                    <Route path="/informaciok/impresszum" element={<ImpressumView />} />
                    <Route path="/kabala" element={<KabalaView />} />
                    <Route path="/informaciok/elsosegely" element={<FirstAidView />} />
                </Route>
                <Route element={<MainLayout location={location} className=" justify-center" />}>
                    <Route path="/informaciok/pszichologus" element={<PsychologistView />} />
                    <Route path='*' exact={true} element={<NotFoundView />} />
                </Route>
            </Routes>
        </div>
    )
}

export default AppRoutes;